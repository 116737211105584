import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import { Link } from "react-router-dom";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Faq3 from "../../ciaComponents/faqs/Faqs3.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";
export default function SelfDrive() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("self-drive-service");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);


  // For Accordian
  const [showAll, setShowAll] = useState(false);
  const faqs = [
    {
      question:
        "1. What varieties of vehicles are available for self-drive rental?",
      answer:
        "Our fleet consists of compact cars, sedans, SUVs, vans, luxury car rental self drive among other models. We offer choices to fit your demands whether you are traveling in a city or on a road trip.",
    },
    {
      question: "2. How do I arrange a self-drive car?",
      answer:
        "You may quickly reserve a car via our website or smartphone app. Just choose your ideal car, note your rental dates, and verify your reservation. Additionally on hand to help with any questions is our customer support staff.",
    },
    {
      question: "3. What is the rental price inclusive of?",
      answer:
        "Our rental rates cover the car, minimum insurance, and round-the-clock roadside support. Extra choices at a separate fee include kid seats and GPS navigation.",
    },
    {
      question: "4. What constitutes the minimum rental period?",
      answer:
        "Usually, the minimum renting time is twenty-four hours. To fit your travel schedule, we do, however, provide flexible choices for daily, weekly, and monthly rentals.",
    },
    {
      question: "5. Does booking require a deposit?",
      answer:
        "Indeed, at the time of booking, you need a refundable security deposit. The type of vehicle you decide on could affect the figure.",
    },
    {
      question: "6. Does renting a car come with age limitations?",
      answer:
        "Drivers must be at least 21 years old and have a current driver's license. Under-25 drivers could be subject to a young driver surcharge.",
    },
    {
      question: "7. Can I rent a car for a longer duration?",
      answer:
        "You are indeed able to prolong your renting duration. To arrange for the extension, kindly get in touch with our customer care staff; make sure you do this before your original rental time finishes.",
    },
    {
      question: "8. How should I proceed if there is an issue with the car?",
      answer:
        "Should you run across any problems with your rental car, kindly call our 24/7 roadside assistance service right away for help. Here we are to help guarantee your comfort and safety.",
    },
    {
      question: "9. Can I use the rented car to travel outside of the nation?",
      answer:
        "Generally speaking, outside-of-country travel is not allowed without prior permission. For particular advice on cross-border travel, kindly refer to our terms and conditions or speak with our experts.",
    },
    {
      question: "10. What happens if I don't return the car on time?",
      answer:
        "Late returns could cost more depending on our rental agreements. To help us minimize additional costs, we advise letting us know ahead of time whether you expect a delay.",
    },
    {
      question: "11. Does the rent cover insurance?",
      answer:
        "Yes, all rentals have basic insurance coverage. For more security, you could also decide to get extra coverage.",
    },
    {
      question: "12. How do I know if my booking is confirmed?",
      answer:
        "Once your booking is paid for, you will get an email confirming all the specifics. Should you not get this, kindly check your spam folder or get help from us.",
    },
    {
      question: "13. Are the cars routinely maintained?",
      answer:
        "Yes, exactly! Every one of our vehicles is routinely maintained and safety checked to guarantee they are in perfect running order for your trip.",
    },
    {
      question:
        "14. At the time of vehicle retrieval, what items should I bring?",
      answer:
        "Please bring your credit card for the deposit, your current driver's license, and a copy of your booking confirmation when picking up your rental car.",
    },
    {
      question: "15. Is my reservation cancelable or flexible?",
      answer:
        "You can, indeed, change or cancel your reservation. For specifics, kindly review our cancellation policy; to make modifications right away, get in touch with us.",
    },
  ];

  const visibleFAQs = showAll ? faqs : faqs.slice(0, 3);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/cia-self-drive-banner.png"}
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white ">Self Drive</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4  ">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mt-3 ">
              <h1 className=" fw-bold  cia-service-heading mb-3">
                Explore Freely with Cars in Africa s Self-Drive Services{" "}
              </h1>

              <div className="underline mb-3"></div>
              <p className="mb-4 fw-normal cia-service-discription">
                Experience the freedom to explore at your leisure with Cars in
                Africas SelfDrive services. Our extensive selection of vehicles,
                which includes compact cars, spacious SUVs, and luxury car
                rentals, allows you to drive according to your preferences,
                whether you are traveling for a brief period or an extended
                period. <br /> <br />
                Our self-drive rentals are suitable for daily, weekly, or
                monthly reservations, and they are ideal for business, leisure,
                or weekend excursions. Travel with ease, regardless of your
                destination, thanks to our competitive rates and well-maintained
                vehicles.
                <br /> <br /> For an effortless self-driving experience, opt for
                Cars in Africa.
              </p>
              <Link to="/contact-us" className="btn cia-contact-us-btn">
                Contact Us &nbsp; <i class="bi bi-arrow-right"></i>
              </Link>
            </div>

            <div className="col-md-6 mt-3 zoom-out  ">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-7.png"
                  }
                  alt="Large"
                  className="img-fluid px-1 "
                />
              </div>
              <div className="d-flex ">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-9.png"
                  }
                  alt="Small 1"
                  className="img-fluid w-50 px-1 "
                />
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-10.png"
                  }
                  alt="Small 2"
                  className="img-fluid w-50 px-1 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section py-5">
        <div className="container">
          {/* Heading */}
          <h2 className="text-center mb-4 cia-why-choose-heading">
            Why Choose <span className="cia-website">Cars In Africa </span>
            for Self Drive?
          </h2>
          <div className="row d-flex justify-content-center mx-2">
            <div className="cia-why-choose-underline mb-3"></div>
          </div>
          {/* Services Cards */}
          <div className="row">
            {/* Card 1 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/diverse-fleet-of-vehicles.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Diverse Fleet of Vehicles
                </h5>
                <p className="why-choose-card-text">
                  Choose from a wide range of well-maintained vehicles,
                  including compact cars, sedans, and spacious SUVs, suitable
                  for any travel requirement.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/flexible-rental-options.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Flexible Rental Options
                </h5>
                <p className="why-choose-card-text">
                  Enjoy the freedom of flexible rental terms with options for
                  daily, weekly, or monthly rentals, making it easy to tailor
                  your experience to your needs
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/affordable-rates.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Affordable Rates</h5>
                <p className="why-choose-card-text">
                  Competitive pricing ensures you get great value without
                  compromising on quality. Transparent pricing with no hidden
                  fees makes budgeting a breeze.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/roadside-assistance.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  24/7 Roadside Assistance
                </h5>
                <p className="why-choose-card-text">
                  Drive with peace of mind knowing our dedicated support team is
                  available around the clock to assist with any issues you may
                  encounter on the road.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            {/* Card 5 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/easy-booking-process.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Easy Booking Process
                </h5>
                <p className="why-choose-card-text">
                  Seamlessly book your vehicle online or via our mobile app.
                  Instant confirmations ensure you can plan your journey without
                  delays.
                </p>
              </div>
            </div>

            {/* Card 6 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/user-friendly-vehicles.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  User Friendly Vehicles
                </h5>
                <p className="why-choose-card-text">
                  All cars are equipped with intuitive features, making them
                  easy to drive and navigate, even for first-time self-drivers.
                </p>
              </div>
            </div>

            {/* Card 7   */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/local-knowledge-support2.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Local Knowledge & Support{" "}
                </h5>
                <p className="why-choose-card-text">
                  Our team provides valuable insights and recommendations on the
                  best routes, attractions, and hidden gems to explore during
                  your journey.
                </p>
              </div>
            </div>

            {/* Card 8   */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/comprehensive-insurance-coverage.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Comprehensive Insurance Coverage{" "}
                </h5>
                <p className="why-choose-card-text">
                  Drive with peace of mind knowing our dedicated support team is
                  available around the clock to assist with any issues you may
                  encounter on the road.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            {/* Card 9 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/flexible-return-options.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Flexible Return Options
                </h5>
                <p className="why-choose-card-text">
                  Enjoy hassle-free returns with multiple drop-off locations and
                  flexible return times to accommodate your travel plans.
                </p>
              </div>
            </div>

            {/* Card 10 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/clean-and-sanitized-vehicles.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Clean and Sanitized Vehicles
                </h5>
                <p className="why-choose-card-text">
                  Each vehicle is thoroughly cleaned and sanitized before each
                  rental, prioritizing your health and safety.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaComp
        tagline="Your Car, Your Adventure! Drive Your Way Today With Our Self Drive Rentals!"
        buttonText="Book Now"
        redirection="/"
      />

      <Faq3 faqs={faqs} title="FAQ's" />
      <Footer />

      <Annimations />
    </>
  );
}
