import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import { Link } from "react-router-dom";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Faq3 from "../../ciaComponents/faqs/Faqs3.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";
export default function CarLease() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("car-lease-service");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  // For Accordian
  const [showAll, setShowAll] = useState(false);
  const faqs = [
    {
      question: "1. What is a car lease?",
      answer:
        "A car lease is a contractual agreement that allows you to use a vehicle for a specified period in exchange for regular payments, without the responsibilities of ownership.",
    },
    {
      question: "2. What types of vehicles are available for lease?",
      answer:
        "We offer a wide range of vehicles, including compact cars, sedans, SUVs, and luxury models. You can choose based on your preferences and needs.",
    },
    {
      question: "3. How long can I lease a vehicle?",
      answer:
        "Our leasing options are flexible, ranging from short-term leases of a few months to long-term leases of several years.",
    },
    {
      question: "4. What’s included in the lease agreement?",
      answer:
        "Our lease agreements typically include the vehicle, maintenance services, comprehensive insurance, and 24/7 roadside assistance. Additional services can be added as needed.",
    },
    {
      question: "5. Are there any mileage restrictions?",
      answer:
        "Yes, mileage limits can vary based on your lease agreement. You can customize your plan to include higher mileage options if needed.",
    },
    {
      question: "6. What happens at the end of the lease term?",
      answer:
        "At the end of the lease term, you can choose to return the vehicle, extend the lease, buy back a rental car, or upgrade to a new model, any damages or excess mileage may incur additional charges.",
    },
    {
      question: "7. Do I need to make a down payment?",
      answer:
        "Depending on the lease terms, a down payment may be required. We offer various financing options to suit your budget.",
    },
    {
      question: "8. Can I customize my lease agreement?",
      answer:
        "Yes, we provide customizable leasing plans to accommodate your specific needs, including mileage limits, maintenance options, and additional services.",
    },
    {
      question: "9. How do I apply for a car lease?",
      answer:
        "You can apply for a car lease by contacting our customer service team or filling out the online application form on our website. We’ll guide you through the process.",
    },
    {
      question: "10. What are the costs associated with leasing a vehicle?",
      answer:
        "Leasing costs typically include monthly payments, insurance, maintenance, and any additional services selected. We provide a transparent breakdown of costs during the application process.",
    },
    {
      question: "11. Is insurance included in the lease?",
      answer:
        "Yes, comprehensive insurance coverage is included in our lease packages, ensuring peace of mind during your leasing period.",
    },
    {
      question:
        "12. What should I do if I encounter issues with the leased vehicle?",
      answer:
        "If you experience any issues with your leased vehicle, simply contact our customer support team. We offer 24/7 assistance and will ensure that any problems are resolved quickly.",
    },
  ];

  const visibleFAQs = showAll ? faqs : faqs.slice(0, 3);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/cia-car-lease-banner.png"}
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Car Lease</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mt-3 ">
              <h1 className=" fw-bold  cia-service-heading mb-3">
                Unlock Flexibility and Convenience with Car Lease Solutions{" "}
              </h1>

              <div className="underline mb-3"></div>
              <p className="mb-4 fw-normal cia-service-discription">
                Optimize your personal or business transportation requirements
                with the adaptable leasing solutions offered by the best car
                rental i.e. Cars in Africa. Our leasing options offer the
                convenience of a modern, well-maintained fleet without the
                hassle of ownership, whether for short-term or long-term use.{" "}
                <br />
                <br />
                Take advantage of customized terms, comprehensive maintenance,
                comprehensive insurance, 24/7 assistance, and predictable costs
                that align with your needs. We provide a variety of vehicles to
                accommodate your operational or lifestyle requirements,
                including executive cars and durable SUVs. Drive with assurance
                while we handle the rest by selecting Cars in Africas lease
                services.
              </p>
              <Link to="/contact-us" className="btn cia-contact-us-btn">
                Contact Us &nbsp; <i class="bi bi-arrow-right"></i>
              </Link>
            </div>

            <div className="col-md-6 mt-3 zoom-out">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/close-up-customer-with-business-person-car-dealership-2.png"
                  }
                  alt="Large"
                  className="img-fluid px-1 "
                />
              </div>
              <div className="d-flex ">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-7.png"
                  }
                  alt="Small 1"
                  className="img-fluid w-50 px-1 "
                />
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-8.png"
                  }
                  alt="Small 2"
                  className="img-fluid w-50 px-1 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section py-5">
        <div className="container">
          {/* Heading */}
          <h2 className="text-center mb-4 cia-why-choose-heading">
            Why Choose <span className="cia-website">Cars In Africa</span> for
            Car Lease Services?
          </h2>
          <div className="row d-flex justify-content-center mx-2">
            <div className="cia-why-choose-underline mb-3"></div>
          </div>
          {/* Services Cards */}
          <div className="row  ">
            {/* Card 1 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/flexible-lease-terms.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Flexible Lease Terms
                </h5>
                <p className="why-choose-card-text">
                  Choose from short-term and long-term leasing options to suit
                  your requirements.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/wide-vehicle-selection.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Wide Vehicle Selection
                </h5>
                <p className="why-choose-card-text">
                  Access a diverse fleet of vehicles, including compact cars,
                  sedans, SUVs, and luxury models.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/maintenance-support.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Maintenance & Support
                </h5>
                <p className="why-choose-card-text">
                  Enjoy hassle-free leasing with included maintenance services,
                  ensuring your vehicle remains in top condition.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/competitive-rates.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Competitive Rates</h5>
                <p className="why-choose-card-text">
                  Benefit from transparent pricing with no hidden fees, making
                  it easier to manage your budget.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            {/* Card 5 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/customizable-plans.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Customizable Plans</h5>
                <p className="why-choose-card-text">
                  Tailor your lease agreement with options for mileage limits
                  and additional services based on your specific needs.
                </p>
              </div>
            </div>

            {/* Card 6 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/comprehensive-insurance.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Comprehensive Insurance
                </h5>
                <p className="why-choose-card-text">
                  Enjoy peace of mind with comprehensive insurance coverage
                  included in your lease package.
                </p>
              </div>
            </div>

            {/* Card 7   */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/no-depreciation-worries.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  No Depreciation Worries{" "}
                </h5>
                <p className="why-choose-card-text">
                  Avoid the headaches of depreciation and resale; simply return
                  the vehicle at the end of your lease term.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaComp
        tagline="Tired Of Frequent Rentals? Ready To Lease Your Perfect Car?"
        buttonText="Book Now"
        redirection="/"
      />

      <Faq3 faqs={faqs} title="FAQ's" />
      <Footer />

      <Annimations />
    </>
  );
}
