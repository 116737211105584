import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { Helmet } from "react-helmet";
export default function Privacypolicy() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);

  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("privacy-policy");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);
  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={process.env.PUBLIC_URL + "/images/privacy.jpg"}
          className="img-fluid"
          alt="privacy"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">Privacy Policy</h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="container full-width pt-4">
        <div className="row">
          <div className="privacybullet">
            <h5>Privacy Notice</h5>
            <p style={{ textAlign: "justify" }}>
              Though we make every effort to preserve the user privacy, we may
              need to disclose personal information when required by law wherein
              we have a good-faith belief that such action is necessary to
              comply with an appropriate law enforcement investigation, current
              judicial proceeding, a court order or legal process served on our
              Site, or as required by law. We may also need to disclose the
              personal information to a third party for the purpose of delivery
              of the services to the users.
            </p>
            <br />
            <h5>General Description</h5>
            <p style={{ textAlign: "justify" }}>
              The Site may collect a user's name, postal address, telephone
              number, mobile number, e- mail address. Besides the above, Members
              intending to create an account may be asked to submit personal
              information including driving license, proof of identity etc.
              besides information pertaining to the Member’s credit card/debit
              card or bank account details. The Site may also be required to
              collect the PAN card details and any such detail which is relevant
              to carry out the transaction. This information is requested if the
              user is entering into a transaction and is further used to deliver
              the services as has been requested by the user. The data so
              collected from the user shall also be used to manage the Site, and
              detect any fraud or site abuses. Other personal information that
              we collect includes the following:
              <br /> <br />
              <b>Vehicle Use Data: </b>Vehicles will contain hardware that
              gathers and transmits information about vehicle use. This is done
              as a security measure against accident or theft and also to
              provide you with valuable services and information, such as other
              drivers' data.
              <br />
              <br />
              <b>Location Tracking: </b>To prevent theft, and to allow us to
              locate you in case of emergency, accident, lock-out, etc., we
              track the location of your vehicle. Your location information will
              be confined to carsinafrica.com service, and we will never
              impermissibly make your location or movements public. As part of
              our service, the location of your vehicle may be released to
              insurance companies, the police, or similar parties in the course
              of an investigation and/or accident claim, and to provide
              assistance in emergencies. Information regarding the location of
              each vehicle on Carsinafrica’s site is also transmitted to
              carsinafrica.com and can be tracked by your Carsinafrica Web
              Portal.
              <br />
              <br />
              <b>Driver and Vehicle Information: </b>As a customer of
              Carsinafrica’s Site, you authorize us to access your driver’s
              record, vehicle history report from all applicable entities and
              authorities. This authorization continues for as long as you are a
              Carsinafrica customer. Moreover, you authorize all DMVs, RMVs,
              auto mechanics and all other entities and interested parties to
              release information to us regarding your driving record and
              vehicle history. You have the right to request that
              carsinafrica.com to discontinue use of your personal information.
              To withdraw your consent to our collection and processing of your
              information at any time, you may do so by closing your account.
              <br />
              <br />
              <b>Collection and Use of Non-Personal Information: </b>We also
              collect non-personal information – data in a form that does not
              permit direct association with any specific individual, including
              IP addresses, usage details and identifying technologies. We may
              use, transfer, collect and disclose non-personal information for
              any purpose. If we do combine non-personal information with
              personal information, the combined information will be treated as
              personal information for as long as it remains combined.
              <br />
              <br />
              <b>Aggregate Information: </b>We may share non-personally
              identifiable information (such as referring/exit pages, anonymous
              usage data, and URLs, platform types, number of clicks, etc.) with
              interested third parties to help them understand the usage
              patterns for certain Carsinafrica services. Third-party ad servers
              or ad networks may serve advertisements on the Carsinafrica Site.
              These third-party ad servers or ad networks may automatically
              receive your IP address when they serve ads to your Internet
              browser. They may also use other technologies (such as cookies,
              JavaScript, or web beacons) to measure the effectiveness of their
              advertisements and to personalize the advertising content. We do
              not provide any personally identifiable information to these
              third-parties without your consent. However, please note that if
              an advertiser asks C to show an advertisement to a certain
              audience and you respond to that advertisement, the advertiser or
              ad-server may conclude that you fit the description of the
              audience they are trying to reach. The Carsinafrica.com Privacy
              Policy does not apply to, and we cannot control the activities of,
              third-party advertisers.
            </p>
            <br />
            <h5>Normal Browsing</h5>
            <p style={{ textAlign: "justify" }}>
              As with many websites, we collect certain information. When you
              browse, read, or download information from the Site, data is
              collected by the Site does not limited to your IP address, URL
              request, browser type, and date and time of your request. We
              collect the IP addresses of visitors, time of access, browser
              type, and web pages visited. We do not collect any other
              information without your knowledge and permission.
            </p>
            <br />
            <h5>Information You Provide</h5>
            <p style={{ textAlign: "justify" }}>
              The site requests personally identifiable information when you
              seek a query from the Contact Us Tab on the Site and is subject to
              the Terms of Use. Such information may be used or requested to
              perform research, improve usability of the site, administer
              mailing lists or online communities, or other activities related
              to the services provided by the Site. This information may
              include, but is not limited to, person’s names, email addresses,
              location. The above mentioned data is collected by the Site only
              with the intention to conduct the business and for the
              facilitation of the correct and timely delivery of the products
              and improvement of the services. The collected data is preserved
              with the Site safely and is not sold/disclosed to a third party,
              whatsoever, beyond the terms of the privacy policy.
            </p>
            <br />
            <h5>Disclosure of Information</h5>
            <p style={{ textAlign: "justify" }}>
              Available log records and all data stored on our servers may be
              accessed by our system administrators. In the event that we are
              required by law (including a court order) to disclose the
              information you submit. We prefer to independently object to
              overly broad requests for access to information about users of our
              site, but we cannot assure you that we will be able to do this in
              all cases. If you do not challenge the disclosure request
              yourself, we may be legally required to turn over your
              information. The data so collected by the site may be disclosed to
              other companies in our group including our agents and
              subcontractors who help us to deliver the goods to you, collect
              payments from you, analyse the data and provide us with the
              marketing or customer service assistance. The data may also be
              shared for the purpose of the fraud protection, credit risk
              reduction, for conducting cases. In the event the business or a
              part of it is sold to any other entity or the business under goes
              a merger/amalgamation/ demerger, the relevant data may be
              transferred to such other new entity. Besides the above, we
              reserve the right to transfer any personal information we have
              about you in the event that we sell or transfer all or a portion
              of our business or assets. When you purchase, register, or
              otherwise express interest in a product or service offered by a
              third party through Carsinafrica.com or sponsored on our site, you
              consent to our sharing your personal information with those
              parties. Participation in rewards programs, discount offers,
              contests, or other programs that involve third parties, authorizes
              Carsinafrica.com to share your personal information with those
              parties. A third party’s use of your information is bound by
              contracts and agreements between Carsinafrica.com and the third
              party. We only share information reasonably needed to provide
              additional services, and such shared information may be bound by
              other privacy agreements. By sharing this information, you agree
              that Carsinafrica.com is not liable or responsible for the actions
              or inactions of third parties.
            </p>
            <br />
            <h5>Security</h5>
            <p style={{ textAlign: "justify" }}>
              The Site’s primary goal in collecting personal information is to
              provide you a fulfilling experience. The information collected by
              the Site is protected by the use of appropriate technical and
              security measures to prevent unauthorized or unlawful access to or
              accidental loss of or destruction or damage to your information.
              However, it is brought to your notice that when you use our
              services or post on the Site, some of the personal information you
              share is visible to other users and can be read, collected, or
              used by them. You are responsible for such personal information
              you choose to submit in these instances. For example, if you list
              your name and email address on the site, that information is
              public. Please take care when using these features. Further, you
              are responsible for maintaining the confidentiality of your
              account and password and for restricting access to your computer,
              and you agree to accept responsibility for all activities that
              occur under your account or password. It is however expressly
              clarified that we will not be responsible for any third party
              access to information provided by you on the Site by any third
              party arising as a result of any unauthorized access or use.
            </p>
            <br />
            <h5>Log Files</h5>
            <p style={{ textAlign: "justify" }}>
              Like most standard web site servers we use log files. This
              includes IP (internet protocol) addresses, browser type, ISP
              (internet service provider), referring / exit pages, platform
              type, date / time stamp and number of clicks to analyze trends,
              administer the site, track user's movement in the aggregate, and
              gather broad demographic information for aggregate use. IP
              addresses, etc. are not linked to personally identifiable
              information. The same may be used to track the behaviour of the
              customers or identify the area of interest of the customer, which
              might further be useful for advertisement, promotions etc.
            </p>
            <br />
            <h5>Cookies</h5>
            <p style={{ textAlign: "justify" }}>
              As set forth above under “Normal Browsing” anonymous users may
              receive session cookies while visiting our Site while their
              browser is open. “Users” are subject to the Site’s Terms of Use.
              Registered Users receive session cookies when they are signed into
              our Site. To facilitate our functions, we may use cookies to
              recognize when you return to our website, if any.
            </p>
            <br />
            <h5>Other Websites</h5>
            <p style={{ textAlign: "justify" }}>
              When you are on the Site and are asked for personal information by
              the Site, you are sharing that information with the Site only.
              However, material on the Site may link to independently run
              websites outside the domain of the Site. The Site is not
              responsible for the privacy practices or content of such websites.
              We encourage you to read the privacy policies of any websites you
              visit from this website as users and other members may be able to
              access certain information that you provide to them (e.g. your
              e-mail address and content that you post on the blog). The Site is
              not in a position to monitor or control any particular user's or
              community member's use of that content.
              <br />
              The site is not engaged in the occupation of storing, procuring or
              Usage of the information (user names, passwords or emails) that
              you May access by the use of your social networking account for
              the Purposes of like, tweeting, +1 button, or mentions and
              subsequently Can’t be held liable for the same. This document is
              an electronic Record in terms of information technology act, 2000
              and rules there Under as applicable and the amended provisions
              pertaining to Electronic records in various statutes as amended by
              the information Technology act, 2000. This electronic record is
              generated by a Computer system and does not require any physical
              or digital Signatures.
            </p>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
