import React, { useLayoutEffect, useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import { Helmet } from "react-helmet";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi.js";
import { Link } from "react-router-dom";
import "./Services.css";
import CtaComp from "../../ciaComponents/CTA/CtaComp.js";
import Faq3 from "../../ciaComponents/faqs/Faqs3.js";
import Annimations from "../../ciaComponents/WebsiteAnnimations/Annimations.js";

export default function EmployeeTransportation() {
  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  useLayoutEffect(() => {
    setMetaDataLoading(true);
    const fetchMetaData = async () => {
      try {
        const data = await getMetaData("employee-transportation-service");
        setMetaData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setMetaDataLoading(false);
      }
    };

    fetchMetaData();
  }, []);

  // For Accordian
  const [showAll, setShowAll] = useState(false);
  const faqs = [
    {
      question:
        "1. What types of vehicles are available for employee transportation?",
      answer:
        "We offer a range of vehicles, including vans, minibusses, and executive coaches, tailored to accommodate various group sizes and comfort levels.",
    },
    {
      question: "2. How can I arrange employee transportation for my business?",
      answer:
        "You can easily arrange employee transportation by contacting our customer service team or filling out the inquiry form on our website. We will work with you to understand your requirements and provide a tailored solution.",
    },
    {
      question: "3. Do you offer fixed routes or customizable plans?",
      answer:
        "We provide both options! You can choose fixed routes for regular commutes or customize the transportation plan based on your company’s specific needs and schedules.",
    },
    {
      question: "4. How do you ensure the safety of passengers?",
      answer:
        "The safety of our passengers is our top priority. All our vehicles undergo regular maintenance checks, and our drivers are professionally trained in safety protocols. We also implement strict health and safety measures and GPS tracking to ensure a secure travel experience.",
    },
    {
      question: "5. Can I track the vehicle during transit?",
      answer:
        "Yes! We provide real-time tracking for our vehicles, allowing you to monitor the location and estimated arrival time of your employee transport.",
    },
    {
      question: "6. What happens if a vehicle is late?",
      answer:
        "We strive for punctuality. If a vehicle is running late, our team will notify you promptly. We also have contingency plans in place to minimize delays and ensure timely arrivals.",
    },
    {
      question: "7. What is the minimum booking requirement?",
      answer:
        "There is no strict minimum booking requirement, but we recommend scheduling transportation for at least a few employees to maximize efficiency. Please contact us for specific details based on your needs.",
    },
    {
      question: "8. Are there any additional fees for customized plans?",
      answer:
        "Customized plans may incur additional fees depending on the complexity of the routes and the services required. We will provide a transparent breakdown of costs before finalizing your booking.",
    },
    {
      question:
        "9. Can you accommodate special requests, such as wheelchair access?",
      answer:
        "Yes! We are committed to inclusivity and can provide vehicles equipped for passengers with special needs. Please inform us of any specific requirements during the booking process.",
    },
    {
      question:
        "10. Is there a cancellation policy for employee transportation bookings?",
      answer:
        "Yes, we have a cancellation policy in place. Cancellations made within a specified timeframe may incur a fee, while cancellations made well in advance are generally processed without charges. Please refer to our terms and conditions for details.",
    },
    {
      question: "11. How do I provide feedback or report an issue?",
      answer:
        "We value your feedback! You can report any issues or provide feedback directly through our website or by contacting our customer service team. We are committed to continuous improvement based on your input.",
    },
    {
      question:
        "12. Can I schedule transportation for special events or occasions?",
      answer:
        "Absolutely! We offer transportation solutions for corporate events, conferences, team-building activities, and more. Contact us to discuss your specific needs.",
    },
    {
      question: "13. What payment options are available?",
      answer:
        "We accept various payment methods, including credit/debit cards, bank transfers, and invoicing for corporate clients. Please inquire for more details during the booking process.",
    },
  ];

  const visibleFAQs = showAll ? faqs : faqs.slice(0, 3);

  return (
    <>
      {!metaDataLoading && (
        <Helmet>
          <title>{`${metaData.title}`}</title>
          <meta name="description" content={`${metaData.description}`} />
          <meta name="keywords" content={`${metaData.keywords}`} />
          <meta property="og:locale" content={`${metaData.ogLocale}`} />
          <meta property="og:title" content={`${metaData.ogTitle}`} />
          <meta
            property="og:description"
            content={`${metaData.ogDescription}`}
          />
          <meta property="og:image" content={`${metaData.ogUrl}`} />
          <meta property="og:url" content={`${metaData.ogUrl}`} />
        </Helmet>
      )}
      <Header />
      <section className="banner-section">
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/cia-employee-transportation-banner.png"
          }
          className="img-fluid"
          alt="services"
        />
        <div className="banner-overlay"></div>
        <div className="container full-width">
          <div className="row justify-content-center align-items-center text-center">
            <div className="col-md-8">
              <h1 className="banner-caption text-white">
                Employee Transportation
              </h1>
              <div className="dark-white-line"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mt-3 slide-left">
              <h1 className=" fw-bold  cia-service-heading mb-3">
                Efficient and Safe Solutions{" "}
              </h1>
              <h6 className="text-muted  fw-semibold ">
                Reliable Daily Commutes for Your Workforce
              </h6>
              <div className="underline mb-3"></div>
              <p className="mb-4 fw-normal cia-service-discription">
                Ensure a reliable, safe, and efficient commute for your
                workforce with Cars in Africa s Employee Transportation
                services. Tailored for businesses, our solutions provides
                hassle-free daily transportation for employees, whether through
                fixed routes or customizable plans. <br />
                <br /> With a fleet of modern, well-maintained vehicles, we
                prioritize punctuality, comfort, and safety, helping your team
                arrive at work relaxed and on time. Simplify your company s
                transport logistics and boost productivity with our professional
                employee transport solutions. Partner with Cars in Africa for
                dependable, cost-effective employee transportation!
              </p>
              <Link to="/contact-us" className="btn cia-contact-us-btn">
                Contact Us &nbsp; <i class="bi bi-arrow-right"></i>
              </Link>
            </div>

            <div className="col-md-6 mt-3 zoom-out">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/close-up-customer-with-business-person-car-dealership-1.png"
                  }
                  alt="Large"
                  className="img-fluid px-1 "
                />
              </div>
              <div className="d-flex ">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-2.png"
                  }
                  alt="Small 1"
                  className="img-fluid w-50 px-1 "
                />
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/elegant-uber-driver-giving-taxi-ride-1.png"
                  }
                  alt="Small 2"
                  className="img-fluid w-50 px-1 "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-section py-5">
        <div className="container">
          {/* Heading */}
          <h2 className="text-center mb-4 cia-why-choose-heading">  
            Why Choose <span className="cia-website">Cars In Africa</span> for
            Employee Transportation?
          </h2>
          <div className="row d-flex justify-content-center mx-2">
            <div className="cia-why-choose-underline mb-3"></div>
          </div>
          {/* Services Cards */}
          <div className="row  ">
            {/* Card 1 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/punctuality.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Punctuality</h5>
                <p className="why-choose-card-text">
                  Our modern, well-maintained fleet ensures timely arrivals,
                  helping your team start and end their day stress-free.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/comfort-and-safety.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Comfort & Safety</h5>
                <p className="why-choose-card-text">
                  Each vehicle is equipped with comfortable seating and safety
                  features, prioritizing the wellbeing of your workforce
                  throughout their journey.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/flexibility.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Flexibility</h5>
                <p className="why-choose-card-text">
                  Choose from various transportation options, including fixed
                  routes for routine commutes or customizable plans that adapt
                  to your business requirements.
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/cost-effective-solutions.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Cost-Effective Solutions
                </h5>
                <p className="why-choose-card-text">
                  Our competitive pricing structure offers a budget-friendly way
                  to manage employee transport, allowing you to focus resources
                  on your core business.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            {/* Card 5 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/dedicated-support.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Dedicated Support</h5>
                <p className="why-choose-card-text">
                  Our professional drivers are trained to provide exceptional
                  service and ensure a pleasant travel experience for all
                  passengers.
                </p>
              </div>
            </div>

            {/* Card 6 */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/real-time-tracking.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">Real-Time Tracking</h5>
                <p className="why-choose-card-text">
                  Stay informed with real-time tracking of vehicles, allowing
                  for efficient management and peace of mind during commutes.
                </p>
              </div>
            </div>

            {/* Card 7   */}
            <div className="col-lg-3 col-md-6 mt-5 zoom-in">
              <div className="card h-100 text-center p-3 d-flex align-items-center justify-content-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/employee-feedback-system.png"
                  }
                  alt="Icon 1"
                  className="why-choose-card-icon mb-3"
                />
                <h5 className="cia-services-card-title">
                  Employee Feedback System{" "}
                </h5>
                <p className="why-choose-card-text">
                  Encourage employee satisfaction with our feedback system,
                  allowing for continuous improvement of transportation
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container ">
          <div className="row">
            {/* left Column */}
            <div className="col-md-6 slide-up">
              <div className="mb-3">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services-images/air-travel-solutions-1.png"
                  }
                  alt="Large"
                  className="img-fluid "
                />
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6 slide-right">
              <h1 className="mb-3 fw-bold">Enhance Your Employee Experience</h1>

              <div className="underline mb-3"></div>
              <p className="mb-4 fw-normal cia-emp-experience">
                With Cars in Africa’s employee transportation services, you can
                foster a positive work environment by ensuring your team arrives
                relaxed and ready to tackle the day. Partner with us for
                reliable and efficient employee transport solutions that
                contribute to your organization’s success. <br /> Contact us
                today to learn more about how we can support your employee
                transportation needs!
              </p>
              <Link to="/contact-us" className="btn cia-contact-us-btn">
                Contact Us &nbsp; <i class="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <CtaComp
        tagline="Need Reliable Transport For Your Team?"
        buttonText="Contact Us"
        redirection="/contact-us"
      />

      <Faq3 faqs={faqs} title="FAQ's" />
      <Annimations />
      <Footer />
    </>
  );
}
