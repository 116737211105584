import React, { useState, useEffect } from "react";
import { MailOutlined, IdcardOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { Input } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
import { getMetaData } from "../../services/Apis/CarsInAfrica/OtherApis/contentApi";
import { useLayoutEffect } from "react";

export default function TrackYourBooking() {
  const [bookingContactDetails, setBookingContactDetails] = useState({});
  const [bookingTrackDetailsToggle, setBookingTrackDetailsToggle] =
    useState(false);
  const [viewBookingDetail, setViewBookingDetail] = useState({});

  const [metaData, setMetaData] = useState(null);
  const [metaDataLoading, setMetaDataLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

    useLayoutEffect(() => {
      setMetaDataLoading(true);
      const fetchMetaData = async () => {
        try {
          const data = await getMetaData("track-your-booking");
          setMetaData(data);
        } catch (error) {
          console.error(error);
        } finally {
          setMetaDataLoading(false);
        }
      };
  
      fetchMetaData();
    }, []);

  const handleUserLoginDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setBookingContactDetails((values) => ({ ...values, [name]: value }));
  };

  const handleUserBookingTracking = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    axios
      .get(
        `https://backend.carsinafrica.com/booking-detailsEmail?booking_id=${bookingContactDetails.booking_id}&email=${bookingContactDetails.email}`
      )
      .then((response) => {
        // Handle the response from the API
        setViewBookingDetail(response.data.data);

        if (response.data.status === "Success") {
          setIsLoading(false);
          Swal.fire({
            title: "Success",
            // text: "Please Check Booking Details",
            icon: "success",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            setBookingTrackDetailsToggle(true);
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Error",
            text: "Invalid Booking Id or Email",
            icon: "error",
            customClass: {
              confirmButton: "custom-confirm-button-class",
            },
          }).then(() => {
            setBookingTrackDetailsToggle(false);
          });
        }
      })
      .catch((error) => {
        // Handle errors that occurred during the request
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const handleCloseViewBookingDetails = () => {
    setBookingTrackDetailsToggle(false);
  };

  if (bookingTrackDetailsToggle === true) {
    return (
      <>
        {!metaDataLoading && (
          <Helmet>
            <title>{`${metaData.title}`}</title>
            <meta name="description" content={`${metaData.description}`} />
            <meta name="keywords" content={`${metaData.keywords}`} />
            <meta property="og:locale" content={`${metaData.ogLocale}`} />
            <meta property="og:title" content={`${metaData.ogTitle}`} />
            <meta
              property="og:description"
              content={`${metaData.ogDescription}`}
            />
            <meta property="og:image" content={`${metaData.ogUrl}`} />
            <meta property="og:url" content={`${metaData.ogUrl}`} />
          </Helmet>
        )}

        {viewBookingDetail.booking_owner === "savaari" ? (
          <div className="container rounded bg-white">
            <div className="bg row d-flex justify-content-center mb-5 mt-5">
              <div className="row pt-2 pb-2" id="payment-section">
                <div className="col-md-9">
                  <div className="pt-2"></div>
                </div>
                <div className="col-md-3" />
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src={viewBookingDetail.vehicle_image}
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="pl-2">
                      <p className="fw-bold fs-4">
                        {viewBookingDetail.car_name}
                      </p>
                      <p className="fw-bold">
                        Car Type: <span>{viewBookingDetail.carType}</span>
                      </p>
                      <p className="fw-bold">
                        Service Package:{" "}
                        <span>{viewBookingDetail.package}</span>
                      </p>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="pl-2">
                            <p className="fw-bold">Pick up</p>
                            <p>{viewBookingDetail.pickup}</p>
                            <p className="fw-bold">
                              <i className="bi bi-geo-alt-fill" />{" "}
                              {viewBookingDetail.pickup_addr}
                            </p>
                          </div>
                        </div>
                        <img
                          src="/images/fluent_arrow-swap-20-regular.svg"
                          style={{ width: 60 }}
                        />
                        <div className="col-md-5">
                          <div className="pl-2">
                            <p className="fw-bold">Drop Location</p>
                            <p>{viewBookingDetail.dropoff}</p>
                            <p className="fw-bold">
                              <i className="bi bi-geo-alt-fill" />{" "}
                              {viewBookingDetail.dest_addr}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pl-2">
                      <p className="fw-bold fs-4">Booking Details</p>
                      <p className="mt-2">Booking ID: {viewBookingDetail.id}</p>
                      <p className="fw-bold">
                        Booking Status:{" "}
                        <span>{viewBookingDetail.ride_status}</span>
                      </p>
                      <p className="fw-bold">Pricing Details </p>
                      <div className="pl-2">
                        <p className="fw-bold">
                          Total : ₹
                          <span>{viewBookingDetail.booking_amount}</span>
                        </p>
                        <div className="row">
                          <div className="col-md-3">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleCloseViewBookingDetails}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container rounded bg-white">
            <div className="bg row d-flex justify-content-center mb-5 mt-5">
              <div className="row pt-2 pb-2" id="payment-section">
                <div className="col-md-9">
                  <div className="pt-2"></div>
                </div>
                <div className="col-md-3" />
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src={`https://backend.carsinafrica.com/uploads/${viewBookingDetail.vehicle_image}`}
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="pl-2">
                      <p className="fw-bold fs-4">
                        {viewBookingDetail.vehicle_make
                          .charAt(0)
                          .toUpperCase() +
                          viewBookingDetail.vehicle_make.slice(1)}{" "}
                        {viewBookingDetail.vehicle_model
                          .charAt(0)
                          .toUpperCase() +
                          viewBookingDetail.vehicle_model.slice(1)}{" "}
                        <span>or Similar</span>
                      </p>

                      <p className="fw-bold">
                        Car Type: <span>{viewBookingDetail.vehicle_type}</span>
                      </p>
                      <p className="fw-bold fs-5">In Details</p>
                      <p>
                        Model: <span>{viewBookingDetail.vehicle_model}</span>
                      </p>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="pl-2">
                            <p className="fw-bold">Pick up</p>
                            <p className="fw-bold">
                              <i className="bi bi-geo-alt-fill" />{" "}
                              {viewBookingDetail.pickup_addr}
                            </p>
                            <p>{viewBookingDetail.pickup}</p>
                          </div>
                        </div>
                        <img
                          src="/images/fluent_arrow-swap-20-regular.svg"
                          style={{ width: 60 }}
                        />
                        <div className="col-md-5">
                          <div className="pl-2">
                            <p className="fw-bold">Drop Location</p>
                            <p className="fw-bold">
                              <i className="bi bi-geo-alt-fill" />{" "}
                              {viewBookingDetail.dest_addr}
                            </p>
                            <p>{viewBookingDetail.dropoff}</p>
                          </div>
                        </div>
                      </div>
                      <div className="fw-bold mb-3">
                        <div className="d-inline-flex align-items-center">
                          <img
                            src="/images/mdi_air-conditioning.svg"
                            alt="AC"
                            className="mr-1"
                          />
                          AC
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/arcticonscar.svg"
                            alt="Sedan"
                            className="mr-1"
                          />
                          &nbsp;
                          {viewBookingDetail.vehicle_type}
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/mdi_petrol-pump-outline.svg"
                            alt="Petrol"
                            className="mr-1"
                          />
                          {viewBookingDetail.vehicle_engine_type}
                        </div>
                        <div className="d-inline-flex align-items-center m-1">
                          <img
                            src="/images/mdi_seat-passenger.svg"
                            alt="4 Seater"
                            className="mr-1"
                          />
                          {viewBookingDetail.vehicle_seats}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="pl-2">
                      <p className="fw-bold fs-4">Booking Details</p>
                      <p className="mt-2">Booking ID: {viewBookingDetail.id}</p>
                      <p className="fw-bold">
                        Booking Status:{" "}
                        <span>{viewBookingDetail.ride_status}</span>
                      </p>
                      <p className="fw-bold">Pricing Details </p>
                      <div className="pl-2">
                        <p className="fw-bold">
                          Total : $
                          <span>{viewBookingDetail.booking_amount}</span>
                        </p>
                        <div className="row">
                          <div className="col-md-3">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleCloseViewBookingDetails}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {isLoading ? (
          <div className="container pt-5 mt-4 mb-5">
            <div className="custom-container-gif">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
        ) : (
          <merg className="registrar mb-5 pt-5">
            <div className="container">
              <div className="row enquiry-section">
                <div className="col-lg-6">
                  <h3 className="display-5 mb-4">Track Your Booking</h3>
                  <form onSubmit={handleUserBookingTracking}>
                    <div className="mb-3">
                      <label for="formGroupExampleInput" className="form-label">
                        Registered Email<span style={{ color: "red" }}> *</span>
                      </label>
                      <div className="input-group">
                        <Input
                          placeholder="Enter your Email"
                          name="email"
                          size={"large"}
                          prefix={
                            <MailOutlined className="site-form-item-icon" />
                          }
                          onChange={handleUserLoginDetails}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="formGroupExampleInput"
                        className="form-label"
                      >
                        Booking Id<span style={{ color: "red" }}> *</span>
                      </label>
                      <div className="input-group">
                        <Input
                          type="number"
                          placeholder="Enter your Booking Id"
                          name="booking_id"
                          size={"large"}
                          prefix={
                            <IdcardOutlined className="site-form-item-icon" />
                          }
                          onChange={handleUserLoginDetails}
                          required
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-success m-1">
                      Submit
                    </button>
                    <Link to="/">
                      <button className="btn track-booking-cancel-btn">
                        Cancel
                      </button>
                    </Link>
                  </form>
                </div>
                <div className="col-lg-6 pt-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/vision.jpg`}
                    className="img-responsive"
                    alt="cars"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </merg>
        )}
      </>
    );
  }
}
